.packagebox.featured {
  border: 2px solid #3a7fff;
  overflow: hidden;
}
  
.packagebox.featured::before {
  content: "  MOST\APOPULAR";
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #3a7fff;
  padding: 0 80px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(20%) translateX(88px) rotate(45deg);
  z-index: 1;
  white-space: pre-wrap;
}
.packagebox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  width: 320px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px;
}

.packagebox h2 {
  font-size: 1.75rem;
  color: rgb(158, 158, 158);
  font-weight: bold;
  text-align: center;
}

.packagebox p {
  font-size: 1.25rem;
  color: rgb(221, 221, 221);
  text-align: center;
  margin: 0.1rem 0;
  padding: 0.1rem 0;
}

.packagebox a {
  background-color: #4CAF50;
  color: white;
  margin: 0.9rem 0;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
}
    
.game-cont {
  max-width: 1160px;
  margin: 0 auto;
}