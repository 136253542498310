body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
  color: #ffffff !important;
}
.dropdown-menu {
  background-color: #2a4786 !important;
}
.navbar{
  background-color: #191919 !important;
}
.nav-link{
  color: hsl(0deg 0% 100% / 100%) !important;
}
.dropdown-item {
  color: #fdfdfd !important;
}

  footer {
    width: flex;
    background: linear-gradient(to bottom, transparent, #343434 9px) !important;
    color: #a7a7a7;
    font-size: 16px;
  }
  footer * {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    border: none;
    outline: none;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1em 1em;
  }
  .row.primary {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 2fr;
    align-items: stretch;
  }
  .column {
    display: flex;
    flex-direction: column;
    padding: 0 2em;
  }
  h3 {
    width: 100%;
    text-align: left;
    color: white;
    font-size: 1.4em;
    white-space: nowrap;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  li:not(:first-child) {
    margin-top: 0.8em;
  }
  ul li a {
    color: #a7a7a7;
    text-decoration: none;
  }
  ul li a:hover {
    color: #2a8ded;
  }
  .about p {
    text-align: justify;
    line-height: 2;
    margin: 0;
  }
  div.social {
    display: flex;
    justify-content: space-around;
    font-size: 2.4em;
    flex-direction: row;
    margin-top: 0.5em;
  }
  .social i {
    color: #bac6d9;
  }
  
  .copyright {
    padding: 0.3em 1em;
    background-color: #25262e;
  }
  .footer-menu{
    float: right;
      margin-top: 10px;
  }
  
  .footer-menu a{
    color: #cfd2d6;
    padding: 6px;
  
    text-decoration: none;
  }
  .footer-menu a:hover{
    color: #27bcda;
  }
  .copyright {
    font-size: 0.9em;
    text-align: left;
    width: flex;
  }
  @media screen and (max-width: 850px) {
    .row.primary {
      grid-template-columns: 1fr;
    }
  
  }
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #121212 !important;
}

.pages {
  color: #c0c0c0;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 10%;
}

r5{
    color: #d4d4d4;
    font-size: 1.1rem;
}
h4{
    color: #ffffff;
}
.top {
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 2560px;
  background-image: url('/img/bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 600px) {
  .top {
    background: none;
  }
}


@media (max-width: 768px) {
  h1 {
    font-size: 2rem !important;
  }
}

.explore-button {
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  width: auto;
  position: relative;
  border: 2px solid lightblue;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.explore-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent;
  border-radius: 5px;
  -webkit-animation: glow 2s ease-in-out infinite;
          animation: glow 2s ease-in-out infinite;
}

.explore-button:hover {
  color: #fff;
  background-color: #7CB9E8;
}

@-webkit-keyframes glow {
  0% {
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
}

@keyframes glow {
  0% {
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
}

.homecontainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.homebox {
    display: flex;
    align-items: center;
    background-color: transparent;
    width: 400px;
    padding: 20px;
    flex-direction: column;
    text-align: center;
}

.homebox h3{
    text-align: center;
    color: #ffffff;
    font-size: 1.4em;
}
  
.homebox img {
    width: 25%;
    margin-right: 0;
    margin-bottom: 20px;
}
  
@media (max-width: 768px) {
    .homebox {
      width: 288px;
      flex-direction: column;
      align-items: center;
    }
    
    .homebox img {
      width: 50%;
      margin-right: 0;
      margin-bottom: 20px;
    }
}

.map-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  max-width: 1080px;
}

.map-container img {
  width: 100%;
  opacity: 0.5;
  height: auto;
}

.dot-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.dot {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #7CB9E8;
  -webkit-animation: pulse 2s ease-in-out infinite;
          animation: pulse 2s ease-in-out infinite;
}

@media (max-width: 768px) {
  .dot {
    width: 3px !important;
    height: 3px !important;
  }
  .dot-info {
    font-size: 6px !important;
    padding: 1px !important;
  }
}

.dot-info {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: rgb(0 0 0 / 57%);
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: bold; 
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

.dot-wrapper:hover .dot-info {
  visibility: visible;
  opacity: 1;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.typing-text span::after {
  content: '|';
  margin-left: 3px;
  opacity: 0;
  -webkit-animation: blink 0.5s linear infinite;
          animation: blink 0.5s linear infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.aboutus {
    width: 60%;
    margin: 0 auto;
    text-align: center;
}
.aboutus p {
  font-size: 24px;
  margin-bottom: 20px;
  color: #c5c5c5;
}
.aboutus h1 {
    font-size: 36px;
    margin-bottom: 20px;
}
.contactus {
    width: 88%;
    margin: 0 auto;
    text-align: center;
}
.contactus p {
  font-size: 24px;
  margin-bottom: 20px;
  color: #c5c5c5;
}
.contactus h1 {
    font-size: 36px;
    margin-bottom: 20px;
}
h1{
  color: #c0c0c0;
  text-align: center;
  font-size: 3.5rem;
}
.minecraft-background {
    background: linear-gradient(to bottom, 
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ), 
    url("https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/image/rDtN98Qoishumwih/brown-dirt-minecraft-pattern_thumb.jpg");
    background-repeat: repeat;
    width: auto;
    height: auto;
}

.thigsbox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  width: 224px; 
  padding: 14px;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 7px;
}

.thigsbox h2 {
  font-size: 1.2rem;
  color: rgb(158, 158, 158);
  font-weight: bold;
  text-align: center;
  margin-bottom: 0rem;
}

.thigsbox p {
  font-size: 0.875rem;
  color: rgb(221, 221, 221);
  text-align: center;
  margin: 0rem 0;
  padding: 0rem 0;
}

.thigsbox a {
  background-color: #4CAF50;
  color: white;
  margin: 0.63rem 0;
  padding: 10.5px 22.4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11.2px;
  border-radius: 3.5px;
}


.locationbox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  width: 200px; 
  padding: 14px;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 7px;
}

.locationbox h2 {
  font-size: 1.2rem;
  color: rgb(158, 158, 158);
  font-weight: bold;
  text-align: center;
  margin-bottom: 0rem;
}

.locationbox p {
  font-size: 0.875rem;
  color: rgb(221, 221, 221);
  text-align: center;
  margin: 0rem 0;
  padding: 0rem 0;
}

.locationbox a {
  background-color: #4CAF50;
  color: white;
  margin: 0.63rem 0;
  padding: 10.5px 22.4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11.2px;
  border-radius: 3.5px;
}
.packagebox.featured {
  border: 2px solid #3a7fff;
  overflow: hidden;
}
  
.packagebox.featured::before {
  content: "  MOST\APOPULAR";
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #3a7fff;
  padding: 0 80px;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateY(20%) translateX(88px) rotate(45deg);
          transform: translateY(20%) translateX(88px) rotate(45deg);
  z-index: 1;
  white-space: pre-wrap;
}
.packagebox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  width: 320px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px;
}

.packagebox h2 {
  font-size: 1.75rem;
  color: rgb(158, 158, 158);
  font-weight: bold;
  text-align: center;
}

.packagebox p {
  font-size: 1.25rem;
  color: rgb(221, 221, 221);
  text-align: center;
  margin: 0.1rem 0;
  padding: 0.1rem 0;
}

.packagebox a {
  background-color: #4CAF50;
  color: white;
  margin: 0.9rem 0;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
}
    
.game-cont {
  max-width: 1160px;
  margin: 0 auto;
}



.servicescontainer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.servicesbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #474747;
    width: 300px;
    box-sizing: border-box;
    border-radius: 10px;
}
  
.servicesbox img {
    width: 100%;
}

.servicestext  {
    padding: 20px;
    width: 100%;
}

.servicesbox a {
    background-color: #4CAF50;
    color: white;
    margin-bottom: 20px;
    padding: 9px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  }
  
@media (max-width: 768px) {
    .servicesbox {
        width: 288px;
        align-items: center;
    }
    
    .servicesbox img {
        width: 100%;
        margin-bottom: 20px;
    }
}

.services-parent-container {
    max-width: 1760px;
    margin: 0 auto; /* to center the container */
  }
.half-screen {
    display: flex;
    align-items: center;
    padding: 20px;
    text-align: center;
  }
  
  .half-screen img {
    max-width: 50%;
    height: auto;
    margin-right: 20px;
    border-radius: 10px; /* Adjust the value as desired */
  }
  
  .half-screen .text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .half-screen h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .half-screen p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #c0c0c0;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  
  .popup-imeg.closing {
    -webkit-animation-name: shrink;
            animation-name: shrink;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  .popup-overlay.closing {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  .popup {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow: hidden;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .popup.open {
    -webkit-animation-name: grow;
            animation-name: grow;
  }
  
  @-webkit-keyframes grow {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes grow {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @-webkit-keyframes shrink {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }

  @keyframes shrink {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  
  
  .popup img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
  
  .text-container {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
    color: #fff;
  }
  
  .text-container.open {
    opacity: 1;
  }
  
  /* Media Query for Mobile Phones */
  @media (max-width: 767px) {
    .half-screen {
      flex-direction: column;
    }
    
    .half-screen img {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }







  .button-container {
    max-width: 30%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }
  
  .button-container button {
    margin-bottom: 10px;
    width: 170px; 
    height: 60px; 
    border: none;
    background-color: #919090; 
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  
  .button-container button:not(:last-child) {
    margin-bottom: -1px; 
  }
  
  .button-container button:first-child {
    border-radius: 5px 5px 0 0;
  }
  
  .button-container button:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  .button-container button:not(.active) {
    opacity: 0.85; 
  }
  
  .text-container {
    max-width: 70%;
    width: 70%;
    flex: 1 1;
    opacity: 1;
    text-align: left;
    visibility: visible;
    transition: opacity 0.5s;
    color: #fff;
  }

  .button-container button:hover {
    -webkit-filter: brightness(105%);
            filter: brightness(105%);
    opacity: 1; 
    box-shadow: 0 0 5px #000; 
  }

  .button-container button.active {
    background-image: linear-gradient(to right, rgb(0, 132, 255) 3px, transparent 3px);
  }

  .info h6 {
    color: #c0c0c0;
    text-align: center;
    font-size: 24px; 
  }






  .comparison-grid {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    color: #fff;
    background-color: rgb(49, 49, 54);
  }
  
  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 10px;
  }
  
  /* Styling for specific cells (hosts) */
  /*td[data-host="SodiumHosting"] {
    background-color: #0064ff;
    color: #fff;
  }
  
  td[data-host="Bisecthosting"] {
    background-color: #ff8400;
    color: #fff;
  }*/
  
  /* Custom styles for each row container */
  .row-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .row-0 {
    height: 30px;
  }
  
  .row-1 {
    height: 30px;
  }
  
  .row-2 {
    height: 30px;
  }
  
  .row-3 {
    height: 30px;
  }
  
  .row-4 {
    height: 30px;
  }
  
  .row-5 {
    height: 30px;
  }
  
  /* Media Query for Mobile Phones */
  @media (max-width: 767px) {
    table {
      width: 95vw; /* Adjust the width for smaller screens */
      font-size: 0.45rem;
    }
  }

.partner {
    width: 85%;
    margin: 0 auto;
    text-align: center;
}
.partner p {
  font-size: 18px;
  margin-bottom: 2px;
  color: #e7e7e7;
    text-align: center;
}
.partner h1 {
    font-size: 36px;
    margin-bottom: 20px;
}
.contact-button {
  display: inline-block;
  background-color: #ff9900;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.contact-button:hover {
  background-color: #ffbf00;
}
.partnerbox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  background-color: rgb(49, 49, 54);
  width: 370px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px;
}

.partnerbox a {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
}

.partnerbox p {
  font-size: 1rem;
  color: rgb(221, 221, 221);
  text-align: left;
}

.partnerbox img {
  width: 110px;
  height: 110px;
  margin-right: 1rem;
}
