.half-screen {
    display: flex;
    align-items: center;
    padding: 20px;
    text-align: center;
  }
  
  .half-screen img {
    max-width: 50%;
    height: auto;
    margin-right: 20px;
    border-radius: 10px; /* Adjust the value as desired */
  }
  
  .half-screen .text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .half-screen h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .half-screen p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #c0c0c0;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  
  .popup-imeg.closing {
    animation-name: shrink;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .popup-overlay.closing {
    animation-name: fadeOut;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  .popup {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow: hidden;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .popup.open {
    animation-name: grow;
  }
  
  @keyframes grow {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes shrink {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  
  .popup img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
  
  .text-container {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
    color: #fff;
  }
  
  .text-container.open {
    opacity: 1;
  }
  
  /* Media Query for Mobile Phones */
  @media (max-width: 767px) {
    .half-screen {
      flex-direction: column;
    }
    
    .half-screen img {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }







  .button-container {
    max-width: 30%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }
  
  .button-container button {
    margin-bottom: 10px;
    width: 170px; 
    height: 60px; 
    border: none;
    background-color: #919090; 
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  
  .button-container button:not(:last-child) {
    margin-bottom: -1px; 
  }
  
  .button-container button:first-child {
    border-radius: 5px 5px 0 0;
  }
  
  .button-container button:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  .button-container button:not(.active) {
    opacity: 0.85; 
  }
  
  .text-container {
    max-width: 70%;
    width: 70%;
    flex: 1;
    opacity: 1;
    text-align: left;
    visibility: visible;
    transition: opacity 0.5s;
    color: #fff;
  }

  .button-container button:hover {
    filter: brightness(105%);
    opacity: 1; 
    box-shadow: 0 0 5px #000; 
  }

  .button-container button.active {
    background-image: linear-gradient(to right, rgb(0, 132, 255) 3px, transparent 3px);
  }

  .info h6 {
    color: #c0c0c0;
    text-align: center;
    font-size: 24px; 
  }






  .comparison-grid {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    color: #fff;
    background-color: rgb(49, 49, 54);
  }
  
  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 10px;
  }
  
  /* Styling for specific cells (hosts) */
  /*td[data-host="SodiumHosting"] {
    background-color: #0064ff;
    color: #fff;
  }
  
  td[data-host="Bisecthosting"] {
    background-color: #ff8400;
    color: #fff;
  }*/
  
  /* Custom styles for each row container */
  .row-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .row-0 {
    height: 30px;
  }
  
  .row-1 {
    height: 30px;
  }
  
  .row-2 {
    height: 30px;
  }
  
  .row-3 {
    height: 30px;
  }
  
  .row-4 {
    height: 30px;
  }
  
  .row-5 {
    height: 30px;
  }
  
  /* Media Query for Mobile Phones */
  @media (max-width: 767px) {
    table {
      width: 95vw; /* Adjust the width for smaller screens */
      font-size: 0.45rem;
    }
  }