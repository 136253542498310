.contactus {
    width: 88%;
    margin: 0 auto;
    text-align: center;
}
.contactus p {
  font-size: 24px;
  margin-bottom: 20px;
  color: #c5c5c5;
}
.contactus h1 {
    font-size: 36px;
    margin-bottom: 20px;
}