
.servicescontainer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.servicesbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #474747;
    width: 300px;
    box-sizing: border-box;
    border-radius: 10px;
}
  
.servicesbox img {
    width: 100%;
}

.servicestext  {
    padding: 20px;
    width: 100%;
}

.servicesbox a {
    background-color: #4CAF50;
    color: white;
    margin-bottom: 20px;
    padding: 9px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  }
  
@media (max-width: 768px) {
    .servicesbox {
        width: 288px;
        align-items: center;
    }
    
    .servicesbox img {
        width: 100%;
        margin-bottom: 20px;
    }
}

.services-parent-container {
    max-width: 1760px;
    margin: 0 auto; /* to center the container */
  }