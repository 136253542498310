* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #121212 !important;
}

.pages {
  color: #c0c0c0;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 10%;
}
