.aboutus {
    width: 60%;
    margin: 0 auto;
    text-align: center;
}
.aboutus p {
  font-size: 24px;
  margin-bottom: 20px;
  color: #c5c5c5;
}
.aboutus h1 {
    font-size: 36px;
    margin-bottom: 20px;
}