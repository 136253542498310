h1{
  color: #c0c0c0;
  text-align: center;
  font-size: 3.5rem;
}
.minecraft-background {
    background: linear-gradient(to bottom, 
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ), 
    url("https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/image/rDtN98Qoishumwih/brown-dirt-minecraft-pattern_thumb.jpg");
    background-repeat: repeat;
    width: auto;
    height: auto;
}

.thigsbox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  width: 224px; 
  padding: 14px;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 7px;
}

.thigsbox h2 {
  font-size: 1.2rem;
  color: rgb(158, 158, 158);
  font-weight: bold;
  text-align: center;
  margin-bottom: 0rem;
}

.thigsbox p {
  font-size: 0.875rem;
  color: rgb(221, 221, 221);
  text-align: center;
  margin: 0rem 0;
  padding: 0rem 0;
}

.thigsbox a {
  background-color: #4CAF50;
  color: white;
  margin: 0.63rem 0;
  padding: 10.5px 22.4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11.2px;
  border-radius: 3.5px;
}


.locationbox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  width: 200px; 
  padding: 14px;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 7px;
}

.locationbox h2 {
  font-size: 1.2rem;
  color: rgb(158, 158, 158);
  font-weight: bold;
  text-align: center;
  margin-bottom: 0rem;
}

.locationbox p {
  font-size: 0.875rem;
  color: rgb(221, 221, 221);
  text-align: center;
  margin: 0rem 0;
  padding: 0rem 0;
}

.locationbox a {
  background-color: #4CAF50;
  color: white;
  margin: 0.63rem 0;
  padding: 10.5px 22.4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11.2px;
  border-radius: 3.5px;
}