
  footer {
    width: flex;
    background: linear-gradient(to bottom, transparent, #343434 9px) !important;
    color: #a7a7a7;
    font-size: 16px;
  }
  footer * {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    border: none;
    outline: none;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1em 1em;
  }
  .row.primary {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 2fr;
    align-items: stretch;
  }
  .column {
    display: flex;
    flex-direction: column;
    padding: 0 2em;
  }
  h3 {
    width: 100%;
    text-align: left;
    color: white;
    font-size: 1.4em;
    white-space: nowrap;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  li:not(:first-child) {
    margin-top: 0.8em;
  }
  ul li a {
    color: #a7a7a7;
    text-decoration: none;
  }
  ul li a:hover {
    color: #2a8ded;
  }
  .about p {
    text-align: justify;
    line-height: 2;
    margin: 0;
  }
  div.social {
    display: flex;
    justify-content: space-around;
    font-size: 2.4em;
    flex-direction: row;
    margin-top: 0.5em;
  }
  .social i {
    color: #bac6d9;
  }
  
  .copyright {
    padding: 0.3em 1em;
    background-color: #25262e;
  }
  .footer-menu{
    float: right;
      margin-top: 10px;
  }
  
  .footer-menu a{
    color: #cfd2d6;
    padding: 6px;
  
    text-decoration: none;
  }
  .footer-menu a:hover{
    color: #27bcda;
  }
  .copyright {
    font-size: 0.9em;
    text-align: left;
    width: flex;
  }
  @media screen and (max-width: 850px) {
    .row.primary {
      grid-template-columns: 1fr;
    }
  
  }