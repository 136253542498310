.navbar-brand {
  color: #ffffff !important;
}
.dropdown-menu {
  background-color: #2a4786 !important;
}
.navbar{
  background-color: #191919 !important;
}
.nav-link{
  color: hsl(0deg 0% 100% / 100%) !important;
}
.dropdown-item {
  color: #fdfdfd !important;
}