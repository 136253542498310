r5{
    color: #d4d4d4;
    font-size: 1.1rem;
}
h4{
    color: #ffffff;
}
.top {
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 2560px;
  background-image: url('/img/bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 600px) {
  .top {
    background: none;
  }
}


@media (max-width: 768px) {
  h1 {
    font-size: 2rem !important;
  }
}

.explore-button {
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  width: auto;
  position: relative;
  border: 2px solid lightblue;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.explore-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent;
  border-radius: 5px;
  animation: glow 2s ease-in-out infinite;
}

.explore-button:hover {
  color: #fff;
  background-color: #7CB9E8;
}

@keyframes glow {
  0% {
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.7);
  }
}

.homecontainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.homebox {
    display: flex;
    align-items: center;
    background-color: transparent;
    width: 400px;
    padding: 20px;
    flex-direction: column;
    text-align: center;
}

.homebox h3{
    text-align: center;
    color: #ffffff;
    font-size: 1.4em;
}
  
.homebox img {
    width: 25%;
    margin-right: 0;
    margin-bottom: 20px;
}
  
@media (max-width: 768px) {
    .homebox {
      width: 288px;
      flex-direction: column;
      align-items: center;
    }
    
    .homebox img {
      width: 50%;
      margin-right: 0;
      margin-bottom: 20px;
    }
}

.map-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  max-width: 1080px;
}

.map-container img {
  width: 100%;
  opacity: 0.5;
  height: auto;
}

.dot-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
}

.dot {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #7CB9E8;
  animation: pulse 2s ease-in-out infinite;
}

@media (max-width: 768px) {
  .dot {
    width: 3px !important;
    height: 3px !important;
  }
  .dot-info {
    font-size: 6px !important;
    padding: 1px !important;
  }
}

.dot-info {
  position: absolute;
  transform: translateX(-50%);
  background-color: rgb(0 0 0 / 57%);
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: bold; 
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

.dot-wrapper:hover .dot-info {
  visibility: visible;
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.typing-text span::after {
  content: '|';
  margin-left: 3px;
  opacity: 0;
  animation: blink 0.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}