.partner {
    width: 85%;
    margin: 0 auto;
    text-align: center;
}
.partner p {
  font-size: 18px;
  margin-bottom: 2px;
  color: #e7e7e7;
    text-align: center;
}
.partner h1 {
    font-size: 36px;
    margin-bottom: 20px;
}
.contact-button {
  display: inline-block;
  background-color: #ff9900;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.contact-button:hover {
  background-color: #ffbf00;
}
.partnerbox {
  border: 2px solid rgb(37, 37, 41);
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  background-color: rgb(49, 49, 54);
  width: 370px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px;
}

.partnerbox a {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
}

.partnerbox p {
  font-size: 1rem;
  color: rgb(221, 221, 221);
  text-align: left;
}

.partnerbox img {
  width: 110px;
  height: 110px;
  margin-right: 1rem;
}